<template>
    <div class="CEESAR-TableLine">
        <div class="CEESAR-TableLineContent" v-on:click="$emit('click')">
            <slot />
        </div>
        <div class="CEESAR-TableLineButtons">
            <slot name="Buttons"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "CEESAR-TableLine"
}
</script>

<style>
</style>